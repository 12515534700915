<template>
  <div>
    <v-app theme="dark" class="ws-empty-layout">
      <v-main>
        <v-container>
          <nuxt-page />
        </v-container>
      </v-main>
    </v-app>
  </div>
</template>
